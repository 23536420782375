import logo from "../../assets/img/logo.svg";
import {Link} from "react-router-dom";
import {useAuth} from "../../contexts/AuthContext";
import {Preload} from "../index";

const PageTop = ({pageTitle = "Page Title"}) => {
    const {loading} = useAuth();

    return (
        <div className="page-top">
            <h1 className="page-top-title">{pageTitle}</h1>
            <Link to="/"><img src={logo} alt="Hunter Media Logo" className="logo"/></Link>
            {loading ? <Preload /> : null}
        </div>
    );
}

export default PageTop;