import {useEffect, useState} from "react";
import {formatIsoDateToDMY} from "../../inc/template-function";
import {setNewAccInfo} from "../../inc/api-functions";
import {useLocation, useNavigate} from "react-router-dom";

const ChangeAccountInfoForm = ({ id, hunters_start_date, name, surname, birthday_date, gender, email }) => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const [form, setForm] = useState({
        id,
        hunters_start_date: formatIsoDateToDMY(hunters_start_date, 'ymd', '-') || '',
        name: name || '',
        surname: surname || '',
        birthday_date: formatIsoDateToDMY(birthday_date, 'ymd', '-') || '',
        gender: gender || '',
        email: email || '',
    });
    const [oldForm, setOldForm] = useState(form);

    useEffect(() => {
        setOldForm(form);
    }, [])

    const reloadPage = () => {
        window.location.href = pathname;
    };

    const isFormChanged = Object.keys(form).some(key => form[key] !== oldForm[key]);

    const onChangeAccInfo = async (e) => {
        e.preventDefault();

        if (isFormChanged) {
            const res = await setNewAccInfo(form);
            if (res.isChanged) {
                alert(res.message);
                reloadPage();
            }
        } else {
            alert('Данные не изменились');
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setForm(prevForm => ({ ...prevForm, [name]: value }));
    };

    return (
        <form className="form change-account-info-form" onSubmit={onChangeAccInfo}>
            <div className="form-group">
                <label htmlFor="name">Name:</label>
                <input type="text" className="login-form_input" id="name" name="name" placeholder="Name" value={form.name} onChange={handleChange} required={true} />
            </div>
            <div className="form-group">
                <label htmlFor="surname">Surname:</label>
                <input type="text" className="login-form_input" id="surname" name="surname" placeholder="Surname" value={form.surname} onChange={handleChange} />
            </div>
            <div className="form-group">
                <label htmlFor="email">Email:</label>
                <input type="email" className="login-form_input" id="email" name="email" placeholder="Email" value={form.email} onChange={handleChange} required={true} />
            </div>
            <div className="form-group">
                <label htmlFor="birthday_date">Birthday Date:</label>
                <input type="date" className="login-form_input" id="birthday_date" name="birthday_date" value={formatIsoDateToDMY(form.birthday_date, 'ymd', '-')} onChange={handleChange} required={true} />
            </div>
            <div className="form-group">
                <label htmlFor="gender">Gender:</label>
                <select className="login-form_input" id="gender" name="gender" value={form.gender} onChange={handleChange} required={true}>
                    <option value="">Select Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="other">Other</option>
                </select>
            </div>
            <div className="form-group">
                <label htmlFor="hunters_start_date">Hunters Start Date:</label>
                <input type="date" className="login-form_input" id="hunters_start_date" name="hunters_start_date" placeholder="Hunters Start Date" value={formatIsoDateToDMY(form.hunters_start_date, 'ymd', '-')} onChange={handleChange} required={true} />
            </div>

            <button className="btn" type="submit" >Enter</button>
        </form>
    );
}

export default ChangeAccountInfoForm;