import React, {createContext, useContext, useEffect, useState} from 'react';
import {fetchVerification} from "../inc/api-functions";
import {useUser} from "./UserContext";
import {useLocation} from "react-router-dom";

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
    const location = useLocation();
    const [loading, setLoading] = useState(true);
    const [isLoggedIn, setIsLoggedIn] = useState(true);
    const { setUserData } = useUser();

    useEffect(() => {
        const checkVerifi = async () => {
            const data = await fetchVerification();
            try {
                setIsLoggedIn(data.login);
                setUserData(data.user);
                setTimeout(() => setLoading(false), 1000);
            } catch (e) {
                setIsLoggedIn(false);
                setUserData({});
                setTimeout(() => setLoading(false), 1000);
            }
        }

        checkVerifi();
    }, [location.pathname])

    return (
        <AuthContext.Provider value={{ isLoggedIn, setIsLoggedIn, loading }}>
            {children}
        </AuthContext.Provider>
    );
};