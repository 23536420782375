import {Menu, PageTop} from "../../components";
import '../../assets/styles/pages/PageNotFound.scss';
const NotFoundPage = () => {
    return (
        <div className="container">
            <div className="page page-not-found">
                <PageTop pageTitle="Not Found" />
                <div className="page-content">
                    <h1>404!</h1>
                </div>
                <Menu />
            </div>
        </div>
    );
}

export default NotFoundPage;