import {useEffect, useState} from 'react';
import { DownOutlined } from '@ant-design/icons';
import { Select } from 'antd';
const MAX_COUNT = 100;
const MySelect = ({value, setValue, tables}) => {
    const [optionsVal, setOptionsVal] = useState(value);

    useEffect(() => {
        console.log(value)
        if (tables && tables.tablesName && Array.isArray(tables.tablesName)) {
            let newOptions = [];
            newOptions = tables.tablesName.map(table => {
                return {'value': table, 'label': table}
            })
            setOptionsVal(newOptions);
        }
    }, [value])

    const suffix = (
        <>
      <span>
        {value.length} / {MAX_COUNT}
      </span>
            <DownOutlined />
        </>
    );
    return (
        <Select
            mode="multiple"
            maxCount={MAX_COUNT}
            value={value}
            size="large"
            style={{
                width: '100%',
            }}
            onChange={setValue}
            suffixIcon={suffix}
            placeholder="Select tables"
            options={optionsVal}
        />
    );
};
export default MySelect;