import axios from "axios";
import Cookies from 'js-cookie';

const fetchVerification = async () => {
    let userData = {};
    const token = Cookies.get('token');
    if (token) {
        await axios.post(`https://hunters-media.com/api/verification`, {token})
            .then(function (res) {
                if (res.data.login) {
                    userData = {user: res.data.user, login: res.data.login};
                } else {
                    Cookies.remove('token', {path: '/'});
                    userData = {user: {}, login: false};
                }
            })
            .catch(function (error) {
                Cookies.remove('token', {path: '/'});
                userData = {user: {}, login: false};
            });
    } else {
        userData = {user: {}, login: false};
    }

    return userData;
}

const fetchSignup = async (data) => {
    const formData = new FormData();

    for (const key in data) {
        formData.append(key, data[key]);
    }

    try {
        const res = await axios.post(`https://hunters-media.com/api/signup`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        return res.data;
    } catch (error) {
        console.error(error);
        return { isSignup: false, message: error };
    }
}

const fetchLogin = async ({login = '', password = ''}) => {
    let userData = {};

    await axios.post(`https://hunters-media.com/api/login`, {login, password})
        .then(function (res) {
            if (res.data.login) {
                userData = {user: res.data.user, login: res.data.login};
                Cookies.set('token', res.data.token, {expires: 60, path: '/'});
            }
        })
        .catch(function (error) {
            userData = {user: {}, login: false};
        });

    return userData;
}

const fetchLogout = async () => {
    try {
        await Cookies.remove('token', {path: '/'});
        return {logout: true}
    } catch (e) {
        return {logout: false}
    }
}

const setNewPassword = async ({id, oldPassword, newPassword}) => {
    try {
        const response = await axios.post(`https://hunters-media.com/api/user/update/pass`, {id, oldPassword, newPassword});
        if (response.data.isChanged) {
            Cookies.remove('token', {path: '/'});
            return {isChanged: true};
        }
    } catch (error) {
        if (error.response && error.response.status === 401) {
            return {isChanged: false, message: "Invalid old password"};
        } else {
            return {isChanged: false, message: "Server error"};
        }
    }
}

const setNewProfilePhoto = async (id, file) => {
    try {
        const formData = new FormData();
        formData.append('id', id.toString());
        formData.append('photo', file);
        const response = await axios.post(`https://hunters-media.com/api/user/update/photo`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        console.log(response)
        if (response && response.data.isUpload) {
            return {isUpload: true, message: "Photo changed"};
        }
    } catch (error) {
        if (error.response && error.response.status === 401) {
            return {isUpload: false, message: "Invalid photo format"};
        } else {
            return {isUpload: false, message: "Server error"};
        }
    }
}

const setNewAccInfo = async (data) => {
    try {
        const response = await axios.post(`https://hunters-media.com/api/user/update/info`, data);
        return {isChanged: true, message: "User information has been changed"};
    } catch (error) {
        if (error.response && error.response.status === 401) {
            return {isChanged: false, message: "Wrong data"};
        } else {
            return {isChanged: false, message: "Server error"};
        }
    }
}

const getAllHMLeads = async ({user_role = 'user', tname, page = 1, datePeriod = [], sortValues = []}) => {
    try {
        if (!page) return {isDone: false, data: {}};

        const response = await axios.post(`https://hunters-media.com/api/leads/all/`, {tname: tname, page, datePeriod, user_role, sortValues});
        return {isDone: true, data: response.data}
    } catch (e) {
        return {isDone: false, data: {}}
    }
}

const getAllHMCampaigns = async ({user_role = 'user', tname, page = 1, datePeriod = []}) => {
    try {
        if (!page) return {isDone: false, data: {}};

        const response = await axios.post(`https://hunters-media.com/api/leads/all/campaigns`, {tname: tname, page, datePeriod, user_role});
        return {isDone: true, data: response.data}
    } catch (e) {
        return {isDone: false, data: {}}
    }
}

const fetchAllTables = async () => {
    try {
        const response = await axios.post(`https://hunters-media.com/api/leads/all/tables`);
        return {isDone: true, data: response.data}
    } catch (e) {
        return {isDone: false, data: {}}
    }
}

const fetchAllUsers = async () => {
    try {
        const response = await axios.post(`https://hunters-media.com/api/users/all/`);
        return {isDone: true, data: response.data}
    } catch (e) {
        return {isDone: false, data: {}}
    }
}

const fetchUserTables = async ({id, tables}) => {
    try {
        const response = await axios.post(`https://hunters-media.com/api/users/set/tables`, {id, tables});
        return {isDone: true, data: response.data}
    } catch (e) {
        return {isDone: false, data: {}}
    }
}

const fetchDeleteUserById = async (id) => {
    try {
        const response = await axios.post(`https://hunters-media.com/api/users/delete/`, {id});
        return {isDone: true, data: response.data}
    } catch (e) {
        return {isDone: false, data: {}}
    }
}

export {
    fetchLogin,
    fetchLogout,
    fetchSignup,
    fetchVerification,
    setNewPassword,
    setNewProfilePhoto,
    setNewAccInfo,
    getAllHMLeads,
    getAllHMCampaigns,
    fetchAllUsers,
    fetchDeleteUserById,
    fetchAllTables,
    fetchUserTables,
}