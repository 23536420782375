import React, { useState, useEffect } from 'react';
import styles from './Modal.module.scss';

const Modal = ({ children, className, content }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (event.target.id === "modal-backdrop") {
                setIsModalVisible(false);
            }
        };

        if (isModalVisible) {
            window.addEventListener('click', handleClickOutside);
        }

        return () => {
            window.removeEventListener('click', handleClickOutside);
        };
    }, [isModalVisible]);

    return (
        <>
            <div className={className} onClick={() => setIsModalVisible(true)} style={{ cursor: 'pointer' }}>
                {children}
            </div>
            {isModalVisible && (
                <div id="modal-backdrop" className={styles.modalBackdrop}>
                    <div className={styles.modalContent}>
                        <button onClick={() => setIsModalVisible(false)} className={styles.closeButton} style={{ border: 'none', background: 'transparent' }}>
                            <img src="/uploads/close.png" alt="Close" style={{ width: '24px', height: '24px' }} />
                        </button>
                        <div className={styles.modalBody}>
                            {content}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Modal;
