import React from "react";
import {Modal} from "../../components";


const Awards = ({awards = []}) => {
    if (!awards || awards.length === 0) {
        return null;
    }

    return (
        <div className="user_awards">
            {
                awards.map((award, index) => (
                    <React.Fragment key={index}>
                        <Modal className="award" content={award.awards_description}>
                            <img src="./uploads/award-icon.png" alt={award.awards_name}/>
                        </Modal>
                    </React.Fragment>
                ))
            }
        </div>
    );
}

export default Awards;
