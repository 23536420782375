import {CampaignsTable, Menu, PageTop} from "../../components";
import {useEffect, useState} from "react";
import {useUser} from "../../contexts/UserContext";
import {getAllHMCampaigns} from "../../inc/api-functions";
import {formatIsoDateToDMY} from "../../inc/template-function";

const CompaignsPage = (props) => {
    const [campaigns, setCampaigns ] = useState([]);
    const [page, setPage] = useState(1);
    const [actualTable, setActualTable] = useState('');
    const [maxPages, setMaxPages] = useState(1);
    const [isMounted, setIsMounted] = useState(false);
    const {userData, setUserData} = useUser();
    const {user_tables, user_role} = userData;

    useEffect( () => {
        setIsMounted(true);
        return () => setIsMounted(false);
    }, []);

    useEffect( () => {
        let today = new Date();
        today = formatIsoDateToDMY(today, 'ymd', '-')

        getCampaigns({datePeriod: ['2023-01-01', today]});
    }, [isMounted, user_tables]);

    const setGroupCampaigns = (data) => {
        const groupCampaigns = {};

        data.forEach(item => {
            const campaignName = item.campaign_name.replace('!!! ', '');
            const adsetName = item.adset_name.replace('!!! ', '');
            const adName = item.ad_name.replace('!!! ', '');
            const totalFD = item.total_fd;

            if (!groupCampaigns[campaignName]) {
                groupCampaigns[campaignName] = {};
            }
            if (!groupCampaigns[campaignName]['totalFD']) {
                groupCampaigns[campaignName]['totalFD'] = 0;
            }
            if (!groupCampaigns[campaignName][adsetName]) {
                groupCampaigns[campaignName][adsetName] = {};
            }
            if (!groupCampaigns[campaignName][adsetName]['childs']) {
                groupCampaigns[campaignName][adsetName]['childs'] = [];
            }
            if (!groupCampaigns[campaignName][adsetName]['totalFD']) {
                groupCampaigns[campaignName][adsetName]['totalFD'] = 0;
            }

            groupCampaigns[campaignName][adsetName]['childs'].push({name: adName, fd: totalFD})
            groupCampaigns[campaignName]['totalFD'] += totalFD;
            groupCampaigns[campaignName][adsetName]['totalFD'] += totalFD;
        });

        return groupCampaigns;
    }

    const getCampaigns = async ({datePeriod = []}) => {
        if ((user_tables && JSON.parse(user_tables)) && page > 0 && (Array.isArray(datePeriod) && datePeriod.length > 0) && isMounted) {
            let tname = actualTable ? actualTable : JSON.parse(user_tables)[0];
            const data = await getAllHMCampaigns({tname, page, datePeriod, user_role});
            if (data.isDone) {
                const groupCampaigns = setGroupCampaigns(data.data.results);
                setCampaigns(groupCampaigns);
                setMaxPages(data.data.maxPage);
            } else {
                setCampaigns([]);
                setMaxPages(0);
            }
        } else {
            setCampaigns([]);
        }
    }

    return (
        <div className="container">
            <div className="page page-statistics">
                <PageTop pageTitle="Hunters Compaigns" />
                <div className="page-content">
                    <CampaignsTable campaigns={campaigns} page={page} maxPages={maxPages} setPage={setPage} getCampaigns={getCampaigns} setCampaigns={setCampaigns} userTables={user_tables} setActualTable={setActualTable} actualTable={actualTable} />
                </div>
                <Menu />
            </div>
        </div>
    );
}

export default CompaignsPage;
