import {BrowserRouter, Route, Routes} from 'react-router-dom';
import {LoginPage, NotFoundPage, ProfilePage, StatisticsPage, SignupPage, UsersPage, CompaignsPage} from "./pages";
import {AuthProvider} from "./contexts/AuthContext";
import ProtectedRoute from "./components/ProtectedRoute";
import {UserProvider} from "./contexts/UserContext";

function App() {

    return (
        <BrowserRouter>
            <UserProvider>
                <AuthProvider>
                    <Routes>
                        <Route path='/' element={
                            <ProtectedRoute>
                                <StatisticsPage/>
                            </ProtectedRoute>
                        }/>
                        <Route path='/statistics' element={
                            <ProtectedRoute>
                                <StatisticsPage/>
                            </ProtectedRoute>
                        }/>
                        <Route path='/compaigns' element={
                            <ProtectedRoute>
                                <CompaignsPage/>
                            </ProtectedRoute>
                        }/>
                        <Route path='/profile' element={
                            <ProtectedRoute>
                                <ProfilePage/>
                            </ProtectedRoute>
                        }/>
                        <Route path='/users' element={
                            <ProtectedRoute>
                                <UsersPage/>
                            </ProtectedRoute>
                        }/>
                        <Route path='/login' element={<LoginPage/>}/>
                        <Route path='/signup' element={<SignupPage/>}/>
                        <Route path='/*' element={<NotFoundPage/>}/>
                    </Routes>
                </AuthProvider>
            </UserProvider>
        </BrowserRouter>
    );
}

export default App;
