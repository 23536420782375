
const ChangePassForm = ({setNewPassword}) => {

    const onChangePass = (e) => {
        e.preventDefault();
        const $oldPassword = e.target.querySelector('[name="old_password"]');
        const $newPassword = e.target.querySelector('[name="new_password"]');
        const oldPassword = e.target[0].value.trim();
        const newPassword = e.target[1].value.trim();


        checkErr([$oldPassword, $newPassword]);

        if (oldPassword.length <= 0) {
            const $oldPasswordContainer = $oldPassword.closest('.form-group');
            if ($oldPasswordContainer) {
                setFormErr($oldPasswordContainer, 'Old password');
            }
        }

        if (newPassword.length <= 0) {
            const $newPasswordContainer = $newPassword.closest('.form-group');
            if ($newPasswordContainer) {
                setFormErr($newPasswordContainer, 'New password');
            }
        }

        if (oldPassword.length > 0 && newPassword.length > 0) {
            setNewPassword({ oldPassword, newPassword });
        }

        function setFormErr(parentEl, inputName) {
            setNewPassword({ username: '', password: '' });
            const errSpan = document.createElement('span');
            errSpan.textContent = `Please input your ${inputName}!`;
            errSpan.classList.add('login-form_errmess');
            if (parentEl) {
                parentEl.insertAdjacentElement('beforeend', errSpan);
            }
        }

        function checkErr(elements = []) {
            elements.forEach(element => {
                const $сontainer = element.closest('.form-group');
                if ($сontainer.querySelector('.login-form_errmess')) {
                    $сontainer.querySelector('.login-form_errmess').remove();
                }
            })
        }
    }

    return (
        <form className="form change-pass-form" onSubmit={onChangePass}>
            <div className="form-group">
                <input type="text" className="login-form_input" name="old_password" placeholder="Old password"/>
            </div>
            <div className="form-group">
                <input type="password" className="login-form_input" name="new_password" placeholder="New password"/>
            </div>
            <button className="btn login-form_btn" type="submit" >Enter</button>
        </form>
    );
}

export default ChangePassForm;