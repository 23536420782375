import {useEffect, useRef, useState} from "react";
import {Navigate, useNavigate} from 'react-router-dom';
import {LoginForm, Preload} from "../../components";
import {fetchLogin} from "../../inc/api-functions";
import {useAuth} from "../../contexts/AuthContext";
import {useUser} from "../../contexts/UserContext";
import logo from "../../assets/img/logo.svg";
import '../../assets/styles/pages/LoginPage.scss'

const LoginPage = () => {
    const [userLoginData, setUserLoginData] = useState({username: '', password: ''});
    const isMounted = useRef(false);
    const navigate = useNavigate();
    const {isLoggedIn, setIsLoggedIn} = useAuth();
    const {setUserData} = useUser();
    const {loading} = useAuth();

    useEffect(() => {
        const attemptLogin = async () => {
            if (isMounted.current) {
                if (userLoginData.username.length > 0 && userLoginData.password.length > 0) {
                    try {
                        const data = await fetchLogin({
                            login: userLoginData.username,
                            password: userLoginData.password
                        });
                        if (data.login) {
                            setIsLoggedIn(data.login);
                            setUserData(data.user);
                            navigate('/statistics');
                        } else {
                            alert('Incorrect login or password.');
                        }
                    } catch (error) {
                        console.error(error);
                    }
                }
            }
        };

        attemptLogin();
    }, [userLoginData]);

    useEffect(() => {
        isMounted.current = true;
    }, []);

    if (isLoggedIn) {
        return <Navigate to="/" replace/>;
    }

    return (
        <div className="container">
            <div className="page page-login">
                <div className="logo">
                    <img src={logo} alt="Hunter Media Logo"/>
                </div>
                <LoginForm setUserLoginData={setUserLoginData} userLoginData={userLoginData}/>
                {loading ? <Preload /> : null}
            </div>
        </div>
    );
}

export default LoginPage;