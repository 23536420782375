import '../../assets/styles/compopnents/_menu.scss';
import {Link, useLocation} from "react-router-dom";
import statisticsIcon from '../../assets/img/pie-chart.png';
import compaignsIcon from '../../assets/img/compaigns.png';
import profileIcon from '../../assets/img/user.png';
import usersIcon from '../../assets/img/team.png';
import {useUser} from "../../contexts/UserContext";
import {useEffect, useState} from "react";

const Menu = () => {
    const {userData} = useUser();
    const {user_role} = userData;
    const {pathname} = useLocation();

    const initialPages = [
        {pageName: 'Profile', pagePath: '/profile', itemIcon: profileIcon},
        {pageName: 'Statistics', pagePath: '/statistics', itemIcon: statisticsIcon},
        {pageName: 'Compaigns', pagePath: '/compaigns', itemIcon: compaignsIcon},
        {pageName: 'Users', pagePath: '/users', itemIcon: usersIcon},
    ];
    const [pages, setPages] = useState(initialPages);

    useEffect(() => {
        if (user_role && user_role !== 'super_admin') {
            setPages(prevPages => prevPages.filter(page => page.pagePath !== '/users'));
        } else {
            setPages(initialPages);
        }
    }, [user_role]);

    const generateMenuItems = () => {
        return pages.map((page, index) => {
            let isActive = page.pagePath === pathname ? ' active' : '';
            if (pathname === '/' && page.pagePath === '/statistics') isActive = ' active';

            return (
                <div className={`menu-item${isActive}`} key={index}>
                    <Link to={page.pagePath}><img src={page.itemIcon} alt={page.pageName}/></Link>
                </div>
            )
        })
    }

    return (
        <div className="menu">
            {generateMenuItems()}
        </div>
    );
}

export default Menu;