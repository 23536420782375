import React, {useEffect, useState} from 'react';
import s from './CampaignsTable.module.scss';
import {formatIsoDateToDMY} from "../../inc/template-function";

const CampaignsTable = ({
                            campaigns,
                            page,
                            actualTable,
                            maxPages,
                            userTables,
                            setPage,
                            getCampaigns,
                            setCampaigns,
                            setActualTable
                        }) => {
    const [startValDate, setStartDate] = useState('2023-01-01');
    const [endValDate, setEndDate] = useState(new Date().toISOString().split('T')[0]);
    const [arrUserTables, setArrUserTables] = useState([]);
    const [costs, setCosts] = useState({});

    useEffect(() => {
        const {startDate, endDate} = checkDate();

        if (startDate && endDate) {
            getCampaigns({datePeriod: [startDate, endDate]});
        }
    }, [actualTable, page, startValDate, endValDate])

    useEffect(() => {
        if (userTables && JSON.parse(userTables)) {
            setArrUserTables(JSON.parse(userTables))
        }
    }, [userTables])
    const checkDate = () => {
        const today = new Date().toISOString().split('T')[0];
        let startDate = startValDate;
        let endDate = endValDate;

        if (startValDate > today) {
            setStartDate(today);
            startDate = today;
        }

        if (endValDate > today) {
            setEndDate(today);
            endDate = today;
        }

        return {startDate, endDate};
    }

    const handlePeriodClick = (period) => {
        const today = new Date();

        let startDate, endDate;

        switch (period) {
            case 'today':
                startDate = new Date(today);
                endDate = new Date(today);
                break;
            case 'yesterday':
                startDate = new Date(today);
                startDate.setDate(today.getDate() - 1);
                endDate = new Date(startDate);
                break;
            case 'last7days':
                startDate = new Date(today);
                startDate.setDate(today.getDate() - 6);
                endDate = new Date(today);
                break;
            case 'last30days':
                startDate = new Date(today);
                startDate.setDate(today.getDate() - 29);
                endDate = new Date(today);
                break;
            case 'thisMonth':
                startDate = new Date(today.getFullYear(), today.getMonth(), 1);
                endDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);
                break;
            case 'lastMonth':
                startDate = new Date(today.getFullYear(), today.getMonth() - 1, 1);
                endDate = new Date(today.getFullYear(), today.getMonth(), 0);
                break;
            case 'allTime':
                startDate = '2023-01-01';
                endDate = new Date(today);
                break;
            default:
                return;
        }

        setStartDate(formatIsoDateToDMY(startDate, 'ymd', '-'));
        setEndDate(formatIsoDateToDMY(endDate, 'ymd', '-'));
    };
    const handlePageChange = (e) => {
        const selectedPage = parseInt(e.target.value);
        setPage(selectedPage);

        const table = document.querySelector(`.${s.table}`);
        if (table) {
            table.scrollIntoView({behavior: 'smooth', block: 'start'});
        }
    };

    const handleTableChange = (e) => {
        setCampaigns([]);
        const selectedTable = e.target.value;
        setActualTable(selectedTable);
        setPage(1);

        const table = document.querySelector(`.${s.table}`);
        if (table) {
            table.scrollIntoView({behavior: 'smooth', block: 'start'});
        }
    };

    const renderPaginationOptions = () => {
        const options = [];
        for (let i = 1; i <= maxPages; i++) {
            options.push(<option key={i} value={i}>{i}</option>);
        }
        return options;
    };

    const handleCostChange = (e) => {
        const cost = e.target.value;

        if (!cost || cost <= 0) {
            e.target.closest('tr').style.backgroundColor = '#fff';

            setCosts(prev => ({
                ...prev,
                [e.target.dataset.className]: 0
            }));

            return;
        }

        if (/^\d*\.?\d*$/.test(cost)) {
            let roi = +((e.target.dataset.fd * 45 - cost) / cost * 100).toFixed(2);

            if (roi < 0) {
                e.target.closest('tr').style.backgroundColor = '#FF2D2E';
            } else if (roi > 0) {
                e.target.closest('tr').style.backgroundColor = '#1bbd26';
            }

            if (roi === 0) {
                roi = '0.00'
            }

            setCosts(prev => ({
                ...prev,
                [e.target.dataset.className]: roi
            }));
        }
    };

    const handleUncoverСampaign = (e) => {
        const $el = e.target.closest("tr");
        const isMain = $el.classList.contains('campaignNameMain');
        const name = $el.dataset.name;
        const showEls = document.querySelectorAll(`[class="child-of-${name}"]`);

        if (showEls.length > 0 && !isMain) {
            showEls.forEach((itm) => {
                const isShow = window.getComputedStyle(itm).getPropertyValue('display') === 'table-row';
                if (isShow) {
                    itm.style.display = 'none';
                    $el.querySelector('.actionIndicator').textContent = '+';
                } else {
                    itm.style.display = 'table-row';
                    $el.querySelector('.actionIndicator').textContent = '-';
                }
            })
        } else if (showEls.length > 0 && isMain) {

            showEls.forEach((itm) => {
                const isShow = window.getComputedStyle(itm).getPropertyValue('display') === 'table-row';
                if (isShow) {
                    const name2 = itm.dataset.name;
                    const showEls2 = document.querySelectorAll(`[class="child-of-${name2}"]`);
                    if (showEls.length > 0) {
                        showEls2.forEach(itm2 => {
                            const isShow2 = window.getComputedStyle(itm2).getPropertyValue('display') === 'table-row';
                            if(isShow2) {
                                itm2.style.display = 'none';
                            }
                        })
                    }
                    itm.style.display = 'none';
                    itm.querySelector('.actionIndicator').textContent = '+';
                    $el.querySelector('.actionIndicator').textContent = '+';
                } else {
                    itm.style.display = 'table-row';
                    $el.querySelector('.actionIndicator').textContent = '-';
                }
            })
        }
    }

    return (
        <div className={s.leadsTable}>
            <div className={s.filters}>
                <div className={s.filterDate}>
                    <div className={s.top}>
                        <div>
                            <input type="date" value={startValDate}
                                   onChange={(e) => setStartDate(formatIsoDateToDMY(e.target.value, 'ymd', '-'))}/>
                        </div>
                        <div>
                            <input type="date" value={endValDate}
                                   onChange={(e) => setEndDate(formatIsoDateToDMY(e.target.value, 'ymd', '-'))}/>
                        </div>
                    </div>
                    <div className={s.bottom}>
                        <button onClick={() => handlePeriodClick('today')}>Today</button>
                        <button onClick={() => handlePeriodClick('yesterday')}>Yesterday</button>
                        <button onClick={() => handlePeriodClick('last7days')}>Last 7 Days</button>
                        <button onClick={() => handlePeriodClick('last30days')}>Last 30 Days</button>
                        <button onClick={() => handlePeriodClick('thisMonth')}>This Month</button>
                        <button onClick={() => handlePeriodClick('lastMonth')}>Last Month</button>
                        <button onClick={() => handlePeriodClick('allTime')}>All Time</button>
                    </div>
                </div>
                <div className={s.filterTables}>
                    <select onChange={handleTableChange}>
                        {arrUserTables.map(table => <option key={table} value={table}>{table}</option>)}
                    </select>
                </div>
            </div>

            <div className={s.table}>
                <table>
                    <thead>
                    <tr>
                        <th>Spend</th>
                        <th>Advertising</th>
                        <th>FD</th>
                        <th>ROI</th>
                    </tr>
                    </thead>
                    <tbody>
                    {Object.keys(campaigns).length > 0 ? (
                        <>
                            {Object.keys(campaigns).map(campaignName => (
                                <React.Fragment key={campaignName}>
                                    <tr className={`campaignNameMain`} data-name={campaignName.replace(/\s/g, "")}>
                                        <td className={s.tableTdSpend}></td>
                                        <td onClick={handleUncoverСampaign} className={`${s.tableTdName} ${s.tableTdNameMain}`}><strong>{campaignName}</strong><span className="actionIndicator">+</span></td>
                                        <td className={s.tableTdFD}>{campaigns[campaignName].totalFD}</td>
                                        <td className={s.tableTdROI}></td>
                                    </tr>
                                    {Object.keys(campaigns[campaignName]).filter(key => key !== 'totalFD').map(adsetName => (
                                        <React.Fragment key={`${campaignName}-${adsetName}`}>
                                            <tr className={`child-of-${campaignName.replace(/\s/g, "")}`} data-name={`${campaignName.replace(/\s/g, "")}-${adsetName.replace(/\s/g, "")}`} style={{display: 'none'}}>
                                                <td className={s.tableTdSpend}><input type="text" onChange={handleCostChange} data-fd={campaigns[campaignName][adsetName].totalFD} data-class-name={`${campaignName}-${adsetName}`}/></td>
                                                <td onClick={handleUncoverСampaign} className={`${s.tableTdName} ${s.tableTdNameParent}`}><p>{adsetName}</p><span style={{display: "inline-block"}} className="actionIndicator">+</span></td>
                                                <td className={s.tableTdFD}>{campaigns[campaignName][adsetName].totalFD}</td>
                                                <td className={`${campaignName}-${adsetName} ${s.tableTdROI}`}>{costs[`${campaignName}-${adsetName}`] ? costs[`${campaignName}-${adsetName}`] + '%' : ''}</td>
                                            </tr>
                                            {campaigns[campaignName][adsetName].childs.map(child => (
                                                <tr key={`${campaignName}-${adsetName}-${child.name}`} className={`child-of-${campaignName.replace(/\s/g, "")}-${adsetName.replace(/\s/g, "")}`} style={{display: 'none'}}>
                                                    <td className={s.tableTdSpend}><input type="text" onChange={handleCostChange} data-fd={child.fd} data-class-name={`${campaignName}-${adsetName}-${child.name}`}/></td>
                                                    <td className={`${s.tableTdName} ${s.tableTdNameChild}`}><p>{child.name}</p></td>
                                                    <td className={s.tableTdFD}>{child.fd}</td>
                                                    <td className={`${campaignName}-${adsetName}-${child.name} ${s.tableTdROI}`}>
                                                        {costs[`${campaignName}-${adsetName}-${child.name}`] ? costs[`${campaignName}-${adsetName}-${child.name}`] + '%' : ''}
                                                    </td>
                                                </tr>
                                            ))}
                                        </React.Fragment>
                                    ))}
                                </React.Fragment>
                            ))}
                        </>
                    ) : <></>}
                    </tbody>
                </table>
            </div>

            {
                maxPages > 0 ? (
                    <div className={s.pagination}>
                        <p>Pagination</p>
                        <select value={page} onChange={handlePageChange}>
                            {renderPaginationOptions()}
                        </select>
                    </div>
                ) : <></>
            }

        </div>
    );
}

export default CampaignsTable;