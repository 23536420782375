import {useUser} from "../../contexts/UserContext";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Menu, PageTop} from "../../components";
import {UsersTables} from "../../components/";
import {fetchAllUsers, fetchDeleteUserById, fetchAllTables} from "../../inc/api-functions";
import {confirmed} from "../../inc/template-function";
import '../../assets/styles/pages/UsersPage.scss';

const UsersPage = () => {
    const {userData} = useUser();
    const {user_role} = userData;
    const navigate = useNavigate();
    const [users, setUsers] = useState([]);
    const [tables, setTables] = useState([]);

    useEffect(() => {
        getAllUsers();
        getAllTables();
    }, [])

    useEffect(() => {
        if (user_role && user_role !== 'super_admin') {
            navigate("/", { replace: true });
        }
    }, [user_role]);

    const getAllUsers = async () => {
        const res = await fetchAllUsers();
        setUsers(res.data);
    }

    const getAllTables = async () => {
        const res = await fetchAllTables();
        setTables(res.data);
    }

    const deleteUserById = async (id) => {
        const isDel = confirmed(`Are you sure you want to remove the user from ID ${id}?`);
        if (isDel) {
            await fetchDeleteUserById(id);
            getAllUsers();
        }
    }

    return (
        <div className="container">
            <div className="page page-users">
                <PageTop pageTitle="Hunters Users" />
                <div className="page-content">
                    <UsersTables users={users} deleteUserById={deleteUserById} tables={tables} getAllUsers={getAllUsers} />
                </div>
                <Menu />
            </div>
        </div>
    );
}

export default UsersPage;