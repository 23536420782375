import loadPNG from '../../assets/img/load.png'

const Preload = () => {
    return (
        <div className="preload"
            style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                background: '#131313',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: '#fff',
                fontSize: '24px',
                zIndex: 1000,
            }}
        >
            <img src={loadPNG} alt="Loading..." className="rotate" style={{
                maxWidth: '150px',
                height: 'auto',
            }}/>
        </div>
    );
}

export default Preload;