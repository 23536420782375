import {LeadsTable, Menu, PageTop} from "../../components";
import {useEffect, useState} from "react";
import {useUser} from "../../contexts/UserContext";
import {getAllHMLeads} from "../../inc/api-functions";
import {formatIsoDateToDMY} from "../../inc/template-function";

const StatisticsPage = (props) => {
    const [leads, setLeads] = useState([]);
    const [page, setPage] = useState(1);
    const [actualTable, setActualTable] = useState('');
    const [maxPages, setMaxPages] = useState(1);
    const [isMounted, setIsMounted] = useState(false);
    const {userData, setUserData} = useUser();
    const {user_tables, user_role} = userData;

    useEffect( () => {
        setIsMounted(true);
        return () => setIsMounted(false);
    }, []);

    useEffect( () => {
        let today = new Date();
        today = formatIsoDateToDMY(today, 'ymd', '-')

        getLeads({datePeriod: ['2023-01-01', today]});
    }, [isMounted, user_tables]);

    const getLeads = async ({datePeriod = [], sortValues}) => {
        if ((user_tables && JSON.parse(user_tables)) && page > 0 && (Array.isArray(datePeriod) && datePeriod.length > 0) && isMounted) {
            let tname = actualTable ? actualTable : JSON.parse(user_tables)[0];
            const data = await getAllHMLeads({tname, page, datePeriod, user_role, sortValues});
            if (data.isDone) {
                setLeads(data.data.results);
                setMaxPages(data.data.maxPage);
            } else {
                setLeads([]);
                setMaxPages(0);
            }
        } else {
            setLeads([]);
        }
    }

    return (
        <div className="container">
            <div className="page page-statistics">
                <PageTop pageTitle="Hunters Statistics" />
                <div className="page-content">
                    <LeadsTable leads={leads} page={page} maxPages={maxPages} setPage={setPage} getLeads={getLeads} setLeads={setLeads} userTables={user_tables} setActualTable={setActualTable} actualTable={actualTable} />
                </div>
                <Menu />
            </div>
        </div>
    );
}

export default StatisticsPage;
