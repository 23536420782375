import s from "../UsersTables/UsersTables.module.scss";
import {Modal} from "../index";
import ChangeUserInfo from "../Forms/ChangeUserInfo";

const UsersTables = ({users, deleteUserById, tables, getAllUsers}) => {

    return (
        <div className={s.userTable}>
            <table>
                <thead>
                <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Role</th>
                    <th>Event</th>
                </tr>
                </thead>
                <tbody>
                {users.map(user => (
                    <tr key={user.id}>
                        <td className={s.userTableTDName}>
                            <Modal className="change-pass-btn" content={<ChangeUserInfo getAllUsers={getAllUsers} userTables={user.user_tables} tables={tables} userId={user.id} />}>
                                <span>{user.name}</span>
                            </Modal>
                        </td>
                        <td>{user.email}</td>
                        <td>{user.user_role}</td>
                        <td className={s.userTableTDEvent}><span onClick={() => deleteUserById(user.id)}>Delete</span></td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
}

export default UsersTables;