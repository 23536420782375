import {useEffect, useRef, useState} from "react";
import {Navigate, useNavigate} from 'react-router-dom';
import {SignupForm, Preload} from "../../components";
import {fetchSignup} from "../../inc/api-functions";
import {useAuth} from "../../contexts/AuthContext";
import logo from "../../assets/img/logo.svg";
import '../../assets/styles/pages/LoginPage.scss'

const SignupPage = () => {
    const [userSignupData, setUserSignupData] = useState({
        hunters_start_date: '',
        name: '',
        surname: '',
        birthday_date: '',
        gender: '',
        email: '',
        password: '',
        profile_image: ''
    });
    const isMounted = useRef(false);
    const navigate = useNavigate();
    const {isLoggedIn} = useAuth();
    const {loading} = useAuth();

    const isAllFieldsFilled = Object.values(userSignupData).every(value => value !== '');
    const attemptSignup = async () => {
        if (isMounted.current && isAllFieldsFilled) {
            try {
                const data = await fetchSignup(userSignupData);
                if (data.isSignup) {
                    alert(data.message);
                    navigate('/login');
                } else {
                    alert(data.message);
                }
            } catch (error) {
                console.error(error);
            }
        }
    };

    useEffect(() => {
        isMounted.current = true;
    }, []);

    if (isLoggedIn) {
        return <Navigate to="/" replace/>;
    }

    return (
        <div className="container">
            <div className="page page-login">
                <div className="logo">
                    <img src={logo} alt="Hunter Media Logo"/>
                </div>
                <SignupForm setUserSignupData={setUserSignupData} userSignupData={userSignupData} attemptSignup={attemptSignup}/>
                {loading ? <Preload /> : null}
            </div>
        </div>
    );
}

export default SignupPage;