import {ChangeAccountInfoForm, ChangePassForm, Menu, Modal, PageTop} from "../../components";
import {useUser} from "../../contexts/UserContext";
import {useEffect, useRef, useState} from "react";
import Awards from "./Awards";
import {formatIsoDateToDMY} from "../../inc/template-function";
import '../../assets/styles/pages/ProfilePage.scss';
import {fetchLogout, setNewPassword, setNewProfilePhoto} from "../../inc/api-functions";
import {useLocation} from "react-router-dom";

const ProfilePage = () => {
    const {userData} = useUser();
    const { id, profile_image, hunters_start_date, name, surname, birthday_date, gender, email, awards } = userData;
    const [userAwards, setUserAwards] = useState({});
    const fileInputRef = useRef(null);
    const { pathname } = useLocation();

    useEffect(() => {
        if (awards) {
            setUserAwards(JSON.parse(awards));
        }
    }, [userData])

    const formatDate = (date) => date && formatIsoDateToDMY(date);

    const onChangePass = async ({ oldPassword, newPassword }) => {
        if (userData.id && oldPassword && newPassword) {
            const result = await setNewPassword({ id: userData.id, oldPassword, newPassword });
            if (result.isChanged) {
                window.location.href = "/";
            } else {
                alert(result.message);
            }
        }
    }

    const onLogout = async () => {
        const res = await fetchLogout();
        if (res.logout) {
            window.location.href = pathname;
        }
    }

    const handleFileChange = async (event) => {
        const res = await setNewProfilePhoto(userData.id, event.target.files[0]);
        if (res.isUpload) {
            alert(res.message);
            window.location.href = pathname;
        }
    };

    const handleClickPhoto = () => {
        fileInputRef.current.click();
    };

    return (
        <div className="container">
            <div className="page page-profile">
                <PageTop pageTitle="Profile"/>
                <div className="page-content">
                    <div className="profile-container">
                        <div className="profile-info">
                            <div className="top">
                                <div className="user_photo" onClick={handleClickPhoto}>
                                    <img src={profile_image} alt="" />
                                    <input style={{display: 'none'}} type="file" onChange={handleFileChange} ref={fileInputRef} />
                                </div>
                                {userAwards && <Awards awards={Object.values(userAwards)} />}
                            </div>
                            {hunters_start_date && <div className="info start-date">В Hunters Media с {formatDate(hunters_start_date)}</div>}
                            {name && <div className="info name">{name}</div>}
                            {surname && <div className="info surname">{surname}</div>}
                            {birthday_date && <div className="info birthday-date">{formatDate(birthday_date)}</div>}
                            {email && <div className="info email">{email}</div>}
                        </div>
                        <Modal className="change-account-info-btn" content={<ChangeAccountInfoForm {...userData} />}>
                            <button className="btn">Change account info</button>
                        </Modal>
                        <Modal className="change-pass-btn" content={<ChangePassForm setNewPassword={onChangePass} />}>
                            <button className="btn">Change password</button>
                        </Modal>
                        <button className="btn logout" onClick={onLogout}>Logout</button>
                    </div>
                </div>
                <Menu />
            </div>
        </div>
    );
}

export default ProfilePage;