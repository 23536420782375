export function formatIsoDateToDMY(isoDate, format = 'dmy', separator = '.') {
    if (!isoDate) return isoDate;

    const date = new Date(isoDate);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();

    if (format === 'dmy') {
        return `${day}${separator}${month}${separator}${year}`;
    } else if (format === 'ymd') {
        return `${year}${separator}${month}${separator}${day}`;
    } else if (format === 'mdy') {
        return `${month}${separator}${day}${separator}${year}`;
    } else {
        return `${day}${separator}${month}${separator}${year}`;
    }
}

export function confirmed(mess) {
    let isDo = window.confirm(mess);
    return isDo;
}
