import React, {useEffect, useState} from 'react';
import {MySelect} from "../index";
import {fetchUserTables} from "../../inc/api-functions";
import {useLocation} from "react-router-dom";

const ChangeUserInfo = ({userTables, tables, userId, getAllUsers}) => {
    const { pathname } = useLocation();
    const [value, setValue] = useState([]);

    useEffect(() => {
        if (userTables) {
            setValue(JSON.parse(userTables))
        }
    }, [userTables])

    const onSetUsersInfo = async (e) => {
        e.preventDefault();

        const res = await fetchUserTables({id: userId, tables: value});
        if (res.isDone) {
            getAllUsers();
            alert('Tables for user changed');
        }
    }

    return (
        <form className="form user-form" onSubmit={onSetUsersInfo}>
            <div className="form-group">
                <MySelect value={value} setValue={setValue} tables={tables} />
            </div>

            <button className="btn user-form_btn" type="submit">
                Enter
            </button>
        </form>
    );
};

export default ChangeUserInfo;
