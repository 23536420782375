import {formatIsoDateToDMY} from "../../inc/template-function";
import {Link} from "react-router-dom";
const SignupForm = ({userSignupData, setUserSignupData, attemptSignup}) => {
    const onSignup = async (e) => {
        e.preventDefault();
        attemptSignup();
    }

    const handleChange = (e) => {
        const {name, value} = e.target;
        setUserSignupData(prevForm => ({...prevForm, [name]: value}));
    };

    const handleFileChange = async (e) => {
        setUserSignupData(prevForm => ({...prevForm, profile_image: e.target.files[0]}));
    };

    return (
        <form className="form signup-form" onSubmit={onSignup}>
            <div className="form-group">
                <label htmlFor="name">Name:</label>
                <input type="text" className="login-form_input" id="name" name="name" placeholder="Name"
                       value={userSignupData.name} onChange={handleChange} required={true}/>
            </div>
            <div className="form-group">
                <label htmlFor="surname">Surname:</label>
                <input type="text" className="login-form_input" id="surname" name="surname" placeholder="Surname"
                       value={userSignupData.surname} onChange={handleChange}/>
            </div>
            <div className="form-group">
                <label htmlFor="email">Email:</label>
                <input type="email" className="login-form_input" id="email" name="email" placeholder="Email"
                       value={userSignupData.email} onChange={handleChange} required={true}/>
            </div>
            <div className="form-group">
                <label htmlFor="email">Password:</label>
                <input type="password" className="login-form_input" id="password" name="password" placeholder="Password"
                       value={userSignupData.password} onChange={handleChange} required={true}/>
            </div>
            <div className="form-group">
                <label htmlFor="birthday_date">Birthday Date:</label>
                <input type="date" className="login-form_input" id="birthday_date" name="birthday_date"
                       value={formatIsoDateToDMY(userSignupData.birthday_date, 'ymd', '-')} onChange={handleChange}
                       required={true}/>
            </div>
            <div className="form-group">
                <label htmlFor="gender">Gender:</label>
                <select className="login-form_input" id="gender" name="gender" value={userSignupData.gender}
                        onChange={handleChange} required={true}>
                    <option value="">Select Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="other">Other</option>
                </select>
            </div>
            <div className="form-group">
                <label htmlFor="hunters_start_date">Hunters Start Date:</label>
                <input type="date" className="login-form_input" id="hunters_start_date" name="hunters_start_date"
                       placeholder="Hunters Start Date" value={formatIsoDateToDMY(userSignupData.hunters_start_date, 'ymd', '-')}
                       onChange={handleChange} required={true}/>
            </div>

            <div className="form-group">
                <label htmlFor="email">Profile Image:</label>
                <input type="file" className="login-form_input" id="profile_image" name="profile_image" placeholder="Profile Image"
                       onChange={handleFileChange} required={true}/>
            </div>
            <button className="btn" type="submit">Enter</button>
            <Link to="/login" className="login-link">Login</Link>
        </form>
    );
}

export default SignupForm;