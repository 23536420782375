import {Link} from "react-router-dom";

const LoginForm = ({setUserLoginData}) => {

    const onLogin = (e) => {
        e.preventDefault();
        const $username = e.target.querySelector('[name="username"]');
        const $password = e.target.querySelector('[name="password"]');
        const username = e.target[0].value.trim();
        const password = e.target[1].value.trim();

        checkErr([$username, $password]);

        if (username.length <= 0) {
            const $usernameContainer = $username.closest('.form-group');
            if ($usernameContainer) {
                setFormErr($usernameContainer, 'username');
            }
        }

        if (password.length <= 0) {
            const $passwordContainer = $password.closest('.form-group');
            if ($passwordContainer) {
                setFormErr($passwordContainer, 'password');
            }
        }

        if (username.length > 0 && password.length > 0) {
            setUserLoginData({ username, password });
        }

        function setFormErr(parentEl, inputName) {
            setUserLoginData({ username: '', password: '' });
            const errSpan = document.createElement('span');
            errSpan.textContent = `Please input your ${inputName}!`;
            errSpan.classList.add('login-form_errmess');
            if (parentEl) {
                parentEl.insertAdjacentElement('beforeend', errSpan);
            }
        }

        function checkErr(elements = []) {
            elements.forEach(element => {
                const $сontainer = element.closest('.form-group');
                if ($сontainer.querySelector('.login-form_errmess')) {
                    $сontainer.querySelector('.login-form_errmess').remove();
                }
            })
        }
    }

    return (
        <form className="form login-form" onSubmit={onLogin}>
            <div className="form-group">
                <input type="text" className="login-form_input" name="username" placeholder="Login"/>
            </div>
            <div className="form-group">
                <input type="password" className="login-form_input" name="password" placeholder="Password"/>
            </div>
            <button className="btn login-form_btn" type="submit" >Enter</button>
            <Link to="/signup" className="signup-link">Signup</Link>
        </form>
    );
}

export default LoginForm;