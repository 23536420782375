import {useEffect, useState} from 'react';
import s from './LeadsTable.module.scss';
import {formatIsoDateToDMY} from "../../inc/template-function";


const LeadsTable = ({leads, page, actualTable, maxPages, userTables, setPage, getLeads, setLeads, setActualTable}) => {
    const [startValDate, setStartDate] = useState('2023-01-01');
    const [endValDate, setEndDate] = useState(new Date().toISOString().split('T')[0]);
    const [arrUserTables, setArrUserTables] = useState([]);
    const [sortValues, setSortValues] = useState([]);

    useEffect(() => {
        const {startDate, endDate} = checkDate();

        if (startDate && endDate) {
            getLeads({datePeriod: [startDate, endDate], sortValues});
        }
    }, [actualTable, page, startValDate, endValDate, sortValues])

    useEffect(() => {
        if (userTables && JSON.parse(userTables)) {
            setArrUserTables(JSON.parse(userTables))
        }
    }, [userTables])
    const checkDate = () => {
        const today = new Date().toISOString().split('T')[0];
        let startDate = startValDate;
        let endDate = endValDate;

        if (startValDate > today) {
            setStartDate(today);
            startDate = today;
        }

        if (endValDate > today) {
            setEndDate(today);
            endDate = today;
        }

        return {startDate, endDate};
    }

    const handlePeriodClick = (period) => {
        const today = new Date();

        let startDate, endDate;

        switch (period) {
            case 'today':
                startDate = new Date(today);
                endDate = new Date(today);
                break;
            case 'yesterday':
                startDate = new Date(today);
                startDate.setDate(today.getDate() - 1);
                endDate = new Date(startDate);
                break;
            case 'last7days':
                startDate = new Date(today);
                startDate.setDate(today.getDate() - 6);
                endDate = new Date(today);
                break;
            case 'last30days':
                startDate = new Date(today);
                startDate.setDate(today.getDate() - 29);
                endDate = new Date(today);
                break;
            case 'thisMonth':
                startDate = new Date(today.getFullYear(), today.getMonth(), 1);
                endDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);
                break;
            case 'lastMonth':
                startDate = new Date(today.getFullYear(), today.getMonth() - 1, 1);
                endDate = new Date(today.getFullYear(), today.getMonth(), 0);
                break;
            case 'allTime':
                startDate = '2023-01-01';
                endDate = new Date(today);
                break;
            default:
                return;
        }

        setStartDate(formatIsoDateToDMY(startDate, 'ymd', '-'));
        setEndDate(formatIsoDateToDMY(endDate, 'ymd', '-'));
    };
    const handlePageChange = (e) => {
        const selectedPage = parseInt(e.target.value);
        setPage(selectedPage);

        const table = document.querySelector(`.${s.table}`);
        if (table) {
            table.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    const handleTableChange = (e) => {
        setLeads([]);
        const selectedTable = e.target.value;
        setActualTable(selectedTable);
        setPage(1);

        const table = document.querySelector(`.${s.table}`);
        if (table) {
            table.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    const handleTableSort = (e) => {
        let $el = e.target;
        if ($el.tagName !== 'TH') $el = $el.parentElement;
        let $sortArrow = $el.querySelector('.sort-arrow');
        let sortBy = $el.dataset.sortName;

        if ($el.classList.contains('sorted')) {
            $el.classList.remove('sorted');
            $sortArrow.innerHTML = '&#9660;';
            setSortValues((prev) => {
                return prev.filter((itm) => itm !== sortBy);
            });
        } else {
            $el.classList.add('sorted');
            $sortArrow.innerHTML = '&#9650;';
            setSortValues((prev) => {
                return [...prev, sortBy];
            });
        }
    };

    const renderPaginationOptions = () => {
        const options = [];
        for (let i = 1; i <= maxPages; i++) {
            options.push(<option key={i} value={i}>{i}</option>);
        }
        return options;
    };

    return (
        <div className={s.leadsTable}>
            <div className={s.filters}>
                <div className={s.filterDate}>
                    <div className={s.top}>
                        <div>
                            <input type="date" value={startValDate} onChange={(e) => setStartDate(formatIsoDateToDMY(e.target.value, 'ymd', '-'))} />
                        </div>
                        <div>
                            <input type="date" value={endValDate} onChange={(e) => setEndDate(formatIsoDateToDMY(e.target.value, 'ymd', '-'))} />
                        </div>
                    </div>
                    <div className={s.bottom}>
                        <button onClick={() => handlePeriodClick('today')}>Today</button>
                        <button onClick={() => handlePeriodClick('yesterday')}>Yesterday</button>
                        <button onClick={() => handlePeriodClick('last7days')}>Last 7 Days</button>
                        <button onClick={() => handlePeriodClick('last30days')}>Last 30 Days</button>
                        <button onClick={() => handlePeriodClick('thisMonth')}>This Month</button>
                        <button onClick={() => handlePeriodClick('lastMonth')}>Last Month</button>
                        <button onClick={() => handlePeriodClick('allTime')}>All Time</button>
                    </div>
                </div>
                <div className={s.filterTables}>
                    <select onChange={handleTableChange}>
                        {arrUserTables.map(table => <option key={table} value={table}>{table}</option>)}
                    </select>
                </div>
            </div>

            <div className={s.table}>
                <table>
                    <thead>
                    <tr>
                        <th>ID</th>
                        <th>Camp.Name</th>
                        <th>ADset Name</th>
                        <th>AD Name</th>
                        <th>Placement</th>
                        <th>Date of subs</th>
                        <th data-sort-name="date_msg" onClick={handleTableSort} className={s.sortableElem}>Date of sms <span className="sort-arrow">&#9660;</span></th>
                        <th data-sort-name="date_reg" onClick={handleTableSort} className={s.sortableElem}>Date reg <span className="sort-arrow">&#9660;</span></th>
                        <th data-sort-name="date_dep" onClick={handleTableSort} className={s.sortableElem}>Date dep <span className="sort-arrow">&#9660;</span></th>
                        <th>Opt type</th>
                    </tr>
                    </thead>
                    <tbody>
                    {leads.length > 0 ?
                        leads.map(lead => (
                            <tr key={lead.id}>
                                <td>{lead.id}</td>
                                <td>{lead.campaign_name}</td>
                                <td>{lead.adset_name}</td>
                                <td>{lead.ad_name}</td>
                                <td>{lead.placement}</td>
                                <td className={s.tdDate}>{formatIsoDateToDMY(lead.date_invite)}</td>
                                <td className={s.tdDate}>{formatIsoDateToDMY(lead.date_msg)}</td>
                                <td className={s.tdDate}>{formatIsoDateToDMY(lead.date_reg)}</td>
                                <td className={s.tdDate}>{formatIsoDateToDMY(lead.date_dep)}</td>
                                <td>{lead.setup == "on" ? "subscribe" : (lead.setup == "off" ? "lead" : 'lead')}</td>
                            </tr>
                        ))
                        : <></>
                    }
                    </tbody>
                </table>
            </div>

            {
                maxPages > 0 ? (
                <div className={s.pagination}>
                    <p>Pagination</p>
                    <select value={page} onChange={handlePageChange}>
                        {renderPaginationOptions()}
                    </select>
                </div>
                ) : <></>
            }

        </div>
    );
}

export default LeadsTable;